var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Admin',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.schools,"server-items-length":_vm.totalSchools,"items-per-page":15,"options":_vm.options,"disable-filtering":"","disable-sort":"","footer-props":{
        'items-per-page-options': [15],
        'show-first-last-page': true
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Schools")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Create School ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"School name","rules":[function (v) { return !!v || 'School name is required'; }],"error-messages":_vm.errors.name},on:{"focus":_vm.CLEAR_ERRORS},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Address","rules":[function (v) { return !!v || 'School address is required'; }],"error-messages":_vm.errors.address},on:{"focus":_vm.CLEAR_ERRORS},model:{value:(_vm.editedItem.address),callback:function ($$v) {_vm.$set(_vm.editedItem, "address", $$v)},expression:"editedItem.address"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.editedItem.license_start,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedItem, "license_start", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedItem, "license_start", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"License start","prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || 'Licence start is required'; }]},model:{value:(_vm.editedItem.license_start),callback:function ($$v) {_vm.$set(_vm.editedItem, "license_start", $$v)},expression:"editedItem.license_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.editedItem.license_start),callback:function ($$v) {_vm.$set(_vm.editedItem, "license_start", $$v)},expression:"editedItem.license_start"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.editedItem.license_start)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({name: 'schoolDetail', params: {id: item.id}})}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("Empty")])]},proxy:true},{key:"item.renew_license",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.canRenew(item.license_expire)},on:{"click":function($event){return _vm.renewLicense(item)}}},[_vm._v(" Renew license ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }