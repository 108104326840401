<template>
  <Admin>
    <v-container>
      <v-row>
        <v-col class="text-left">
          <h1>Stats</h1>
        </v-col>
      </v-row>
    </v-container>
  </Admin>
</template>

<script>
import Admin from "../../layouts/Admin";

export default {
  components: {Admin}
}
</script>