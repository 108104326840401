<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet color="grey lighten-4" class="pa-4">
        <div>{{ user.email }}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, link] in links"
          :key="icon"
          link
          :to="link"
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon> mdi-logout-variant </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
  }),
  computed: {
    ...mapGetters("auth", [
      "user",
      "isSuperAdmin",
      "isSchoolAdmin",
      "isTeacher",
    ]),
    links() {
      if (this.isTeacher) {
        return [
          ["mdi-school", "Class", "/class"],
          ["mdi-chart-bar", "Stats", "/stats"],
        ];
      }

      if (this.isSchoolAdmin) {
        return [
          ["mdi-school", "School", "/school"],
          ["mdi-checkbox-multiple-marked", "Classes", "/skills"],
          ["mdi-view-dashboard", "Teachers", "/teachers"],
        ];
      }

      const links = [
        ["mdi-school", "Schools", "/schools"],
        ["mdi-checkbox-multiple-marked", "Skills", "/skills"],
        ["mdi-newspaper-variant-outline", "News", "/news"],
      ];

      if (this.isSuperAdmin) {
        links.push(["mdi-view-dashboard", "Dashboard", "/dashboard"]);
      }

      return links;
    },
  },
  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    async logout() {
      await this.logoutAction();

      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
</style>