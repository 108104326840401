import axios from "axios";
import router from "../../router";
import Role from '../../helpers/Role';

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: null,
        error: ''
    },
    getters: {
        authenticated(state) {
            return state.authenticated
        },
        user(state) {
            return state.user;
        },
        error(state) {
            return state.error;
        },
        isAdmin(state) {
            return state.user.role.name === Role.Admin;
        },
        isSuperAdmin(state) {
            return state.user.role.name === Role.SuperAdmin;
        },
        isSchoolAdmin(state) {
            return state.user.role.name === Role.SchoolAdmin;
        },
        isTeacher(state) {
            return state.user.role.name === Role.Teacher
        }
    },
    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },
        SET_USER(state, value) {
            state.user = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        },
        CLEAR_ERROR(state) {
            state.error = '';
        }
    },
    actions: {
        async login({ dispatch, commit }, credentials) {
            try {
                await axios.get('/sanctum/csrf-cookie')
                await axios.post('/api/admin/login', credentials)
                commit('CLEAR_ERROR');
                await dispatch('me');
                router.push({ name: 'schools' });
            } catch (e) {
                return commit('SET_ERROR', e.response.data.message);
            }
        },
        async logout({ dispatch }) {
            await axios.post('/api/admin/logout')

            return dispatch('me')
        },

        me({ commit }) {
            return axios.get('/api/me').then((response) => {
                commit('SET_AUTHENTICATED', true)
                commit('SET_USER', response.data)
            }).catch(() => {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
            })
        }
    }
}