<template>
  <Admin>
    <Skills
      v-if="user.role.name == 'School Admin'"
      :school-id="user.school_id"
    ></Skills>
    <v-expansion-panels v-else>
      <v-expansion-panel v-for="school in schools" :key="school.id">
        <v-expansion-panel-header>
          {{ school.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Skills :school-id="school.id"></Skills>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </Admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Admin from "../layouts/Admin";
import Skills from "../components/Skills";

export default {
  components: { Admin, Skills },
  computed: {
    ...mapGetters("school", ["schools"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("school", ["fetchAllSchools"]),
    ...mapActions("skill", ["fetchSkills"]),
  },
  mounted() {
    this.fetchAllSchools();
    this.fetchSkills();
  },
};
</script>

<style scoped>
</style>