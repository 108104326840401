import axios from "axios";

export default {
    namespaced: true,
    state: {
        users: [],
        totalUsers: 0,
        schoolAdmins: [],
        totalSchoolAdmins: 0
    },
    getters: {
        users(state) {
            return state.users;
        },
        totalUsers(state) {
            return state.totalUsers;
        },
        schoolAdmins(state) {
            return state.schoolAdmins;
        },
        totalSchoolAdmins(state) {
            return state.totalSchoolAdmins;
        }
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_TOTAL_USERS(state, totalUsers) {
            state.totalUsers = totalUsers;
        },
        SET_SCHOOL_ADMINS(state, schoolAdmins) {
            state.schoolAdmins = schoolAdmins;
        },
        SET_TOTAL_SCHOOL_ADMINS(state, totalAdmins) {
            state.totalAdmins = totalAdmins;
        },
        ADD_USER(state, user) {
            state.users.push(user);
            state.totalUsers++;
        },
        ADD_SCHOOL_ADMIN(state, user) {
            state.schoolAdmins.push(user);
            state.totalUsers++;
        },
        UPDATE_USER(state, { userIndex, user }) {
            state.users.splice(userIndex, 1, user);
        },
        DELETE_USER(state, userIndex) {
            state.users.splice(userIndex, 1);
            state.totalUsers--;
        },
        DELETE_SCHOOL_ADMIN(state, userIndex) {
            state.schoolAdmins.splice(userIndex, 1);
            state.totalUsers--;
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async fetchUsers({ commit }, page = 1) {
            const response = await axios.get('api/users?page=' + page);
            commit('SET_USERS', response.data.data);
            return commit('SET_TOTAL_USERS', response.data.total);
        },

        async fetchSchoolAdminsById({ commit }, schoolId) {
            const response = await axios.get('/api/schools/' + schoolId + '/admins');
            return commit('SET_SCHOOL_ADMINS', response.data);

        },
        async fetchTeachers({ commit }, page = 1) {
            const response = await axios.get('api/school/teachers?page=' + page);
            commit('SET_USERS', response.data.data);
            return commit('SET_TOTAL_USERS', response.data.total);
        },

        async fetchFreeTeachersBySchoolId({ commit }, schoolId) {
           
            const response = await axios.get('/api/schools/' + schoolId + '/teachers-without-class');
            commit('SET_USERS', response.data);
            return commit('SET_TOTAL_USERS', response.data.total);
        },

        async fetchAllTeachersBySchoolId({ commit }, schoolId) {
            const response = await axios.get('/api/schools/' + schoolId + '/teachers-all');
            return commit('SET_USERS', response.data);
        },

        async storeUser({ commit }, user) {
            const response = await axios.post('/api/users', user);
            return commit('ADD_USER', response.data.user);
        },
        async storeSchoolAdmin({ commit }, user) {
            const response = await axios.post('/api/users', user);
            return commit('ADD_SCHOOL_ADMIN', response.data.user);
        },

        async updateUser({ commit }, { userIndex, user }) {
            const response = await axios.put('/api/users/' + user.id, user);
            return commit('UPDATE_USER', { userIndex, user: response.data.user });
        },

        async deleteUser({ commit }, { userIndex, userId }) {
            await axios.delete('/api/users/' + userId);
            return commit('DELETE_USER', userIndex);
        },

        async deleteSchoolAdmin({ commit }, { userIndex, userId }) {
            await axios.delete('/api/users/' + userId);
            return commit('DELETE_SCHOOL_ADMIN', userIndex);
        }
    }
}