import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import axios from "axios";
import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)

Vue.config.productionTip = false

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

store.dispatch('auth/me').then(() => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

