import Vue from 'vue'
import VueRouter from 'vue-router'
import Schools from "../pages/Schools/Schools";
import store from '../store'
import SchoolDetail from "../pages/Schools/SchoolDetail";
import Dashboard from "../pages/Dashboard";
import News from "../pages/News";
import Role from '../helpers/Role';
import Teachers from "../pages/SchoolAdminView";
import Class from "../pages/Teacher/Class";
import Stats from "../pages/Teacher/Stats";
import Skills from "../pages/Skills";

Vue.use(VueRouter)

function requireAuth(to, from, next) {
    if (!store.getters["auth/authenticated"]) {
        next('/login')
    } else {
        if (to.meta.roles) {
            if (to.meta.roles.includes(store.getters["auth/user"].role.name)) {
                next();
            } else {
                // store.getters["auth/user"].role.name === Role.Teacher ? next('/class') : next('/schools');
                if (store.getters["auth/user"].role.name === Role.Teacher) {
                    next('/class')
                } else {
                    if (store.getters["auth/user"].role.name === Role.SchoolAdmin) {
                        next('/school/')
                    } else {
                        next('/schools')
                    }

                }
            }
        } else {
            next();
        }
    }
}

const routes = [
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue')
    },
    {
        path: '/schools',
        name: 'schools',
        component: Schools,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SuperAdmin, Role.Admin]
        }
    },
    {
        path: '/schools/:id',
        name: 'schoolDetail',
        component: SchoolDetail,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SuperAdmin, Role.Admin]
        }
    },
    {
        path: '/skills',
        name: 'skills',
        component: Skills,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SuperAdmin, Role.Admin, Role.SchoolAdmin]
        }
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SuperAdmin, Role.Admin]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SuperAdmin]
        }
    },
    {
        path: '/school',
        name: 'school',
        component: SchoolDetail,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SchoolAdmin]
        }
    },
    {
        path: '/teachers',
        name: 'teachers',
        component: Teachers,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.SchoolAdmin]
        }
    },
    {
        path: '/class',
        name: 'class',
        component: Class,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.Teacher]
        }
    },
    {
        path: '/stats',
        name: 'stats',
        component: Stats,
        beforeEnter: requireAuth,
        meta: {
            roles: [Role.Teacher]
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
