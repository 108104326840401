<template>
  <Admin>
    <v-container>
      <v-row class="text-left">
        <v-col class="d-flex align-center">Class: {{ schoolClass.name }}</v-col>
        <v-col class="d-flex align-center"
          >School: {{ schoolClass.school.name }}</v-col
        >
        <v-col class="d-flex align-center"
          >Students: {{ schoolClass.students.length }}</v-col
        >
        <v-col class="text-right">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Invite
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Invite student</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Name"
                          v-model="student.name"
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Email"
                          v-model="student.email"
                          :rules="emailRules"
                          :error-messages="student.error"
                          @blur="checkStudentExistence"
                          @focus="student.error = ''"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="Parent name"
                          v-model="student.parent_name"
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="Parent email"
                          v-model="student.parent_email"
                          :rules="emailRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="clear">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    v-for="skill in skills"
                    :key="skill.id"
                  >
                    {{ skill.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="skill in skills" :key="skill.id">
                    <div class="d-flex justify-center">
                      <v-checkbox
                        @click="toggleSkill(skill.id)"
                        v-model="skill.enabled"
                      ></v-checkbox>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Parent Consent
                  </th>

                  <th class="text-left">
                    Last activity
                  </th>
                  <th class="text-left">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-if="!studentsCount">
                  <td colspan="4">Empty</td>
                </tr>
                <tr
                  v-for="(student, index) in schoolClass.students"
                  :key="student.id"
                  class="text-left"
                  v-else
                >
                  <td>{{ student.name }}</td>
                  <td>{{ student.email }}</td>

                  <td>
                    <span
                      v-if="
                        !!student &&
                          !!student.parent &&
                          !!student.parent.name &&
                          !!student.parent.email
                      "
                    >
                      <!-- <v-icon
                        :color="`${student.parent_consent ? 'success' : ''}`"
                        size="large"
                        class="mr-2"
                        >mdi-check-bold</v-icon
                      > -->
                      {{ student.parent_consent ? "Approved" : "Waiting" }}
                    </span>
                  </td>

                  <td>tbd</td>
                  <td>
                    <v-btn
                      icon
                      color="red"
                      @click="
                        deleteStudent({
                          studentIndex: index,
                          studentId: student.id,
                        })
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </Admin>
</template>

<script>
import Admin from "../../layouts/Admin";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    Admin,
  },
  data() {
    return {
      dialog: false,
      valid: true,
      student: {
        name: "",
        email: "",
        parent_name: "",
        parent_email: "",
        error: "",
      },
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters("schoolClass", ["schoolClass"]),
    ...mapGetters("skill", ["skills"]),
    studentsCount() {
      return this.schoolClass.students.length;
    },
  },
  methods: {
    ...mapActions("schoolClass", [
      "fetchClass",
      "deleteStudent",
      "inviteStudent",
    ]),
    ...mapActions("skill", ["fetchSkills", "toggleSkill"]),
    checkStudentExistence() {
      return axios
        .get("/api/users/exists?email=" + this.student.email)
        .then(
          (res) =>
            (this.student.error = res.data
              ? "User with such email already exist"
              : "")
        );
    },
    async save() {
      await this.checkStudentExistence();
      if (this.$refs.form.validate() && !this.student.error) {
        this.inviteStudent(this.student);
        this.clear();
      }
    },
    clear() {
      this.dialog = false;
      this.student.name = "";
      this.student.email = "";
      this.student.parent_name = "";
      this.student.parent_email = "";
      this.student.error = "";
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.fetchClass();
    this.fetchSkills();
  },
};
</script>
