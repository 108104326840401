import axios from "axios";

export default {
    namespaced: true,
    state: {
        notifications: []
    },
    getters: {
        notifications(state) {
            return state.notifications;
        }
    },
    mutations: {
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        },
        ADD_NOTIFICATION(state, notification) {
            state.notifications.unshift(notification);
        }
    },
    actions: {
        async fetchNotifications({ commit }) {
            const response = await axios.get('/api/notifications')
            commit('SET_NOTIFICATIONS', response.data);
        },
        async storeNotification({commit}, notification) {
            const response = await axios.post('/api/notifications', notification);
            commit('ADD_NOTIFICATION', response.data.notification);
        }
    }
};