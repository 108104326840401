import axios from "axios";

export default {
    namespaced: true,
    state: {
        skills: []
    },
    getters: {
        skills(state) {
            return state.skills;
        }
    },
    mutations: {
        SET_SKILLS(state, skills) {
            state.skills = skills;
        },
        TOGGLE_SKILL(state, skillId) {
            const skill = state.skills.find((skill) => skill.id === skillId);
            skill.enabled = !! skill.enabled;
        }
    },
    actions: {
        async fetchSkills({commit}) {
            const response = await axios.get('/api/class/skills');
            commit('SET_SKILLS', response.data);
        },
        async toggleSkill({commit, rootState}, skillId) {
            await axios.put('/api/classes/' + rootState.auth.user.class.id + '/skills/' + skillId + '/toggle');
            return commit('TOGGLE_SKILL', skillId);
        }
    }
}