import axios from "axios";

export default {
    namespaced: true,
    state: {
        schoolClass: null,
        schoolClasses: []
    },
    getters: {
        schoolClasses(state) {
            return state.schoolClasses;
        },
        schoolClass(state) {
            return state.schoolClass;
        }
    },
    mutations: {
        SET_CLASSES(state, schoolClasses) {
            state.schoolClasses = schoolClasses;
        },
        SET_CLASS(state, schoolClass) {
            state.schoolClass = schoolClass;
        },
        ADD_CLASS(state, schoolClass) {
            state.schoolClasses.push(schoolClass);
        },
        ADD_STUDENT(state, student) {
            state.schoolClass.students.push(student);
        },
        UPDATE_CLASS(state, {classIndex, schoolClass}) {
            state.schoolClasses.splice(classIndex, 1, schoolClass.class);
        },
        DELETE_CLASS(state, classIndex) {
            state.schoolClasses.splice(classIndex, 1);
        },
        DELETE_STUDENT(state, studentIndex) {
            state.schoolClass.students.splice(studentIndex, 1);
        }
    },
    actions: {
        async fetchClasses({commit}, schoolId) {
            const response = await axios.get('/api/schools/' + schoolId + '/classes');
            commit('SET_CLASSES', response.data);
        },
        async fetchClassesWithSkills({commit}, schoolId) {
            const response = await axios.get('/api/schools/' + schoolId + '/classes-with-skills');
            commit('SET_CLASSES', response.data);
        },
        async fetchClass({commit}) {
            const response = await axios.get('/api/class');
            commit('SET_CLASS', response.data)
        },
        async storeClass({commit}, {schoolId, schoolClass}) {
            const data = await axios.post('/api/schools/' + schoolId + '/classes', schoolClass);
            return commit('ADD_CLASS', data.data.class);
        },
        async updateClass({commit}, { classIndex, schoolClass }) {
            const response = await axios.put('/api/classes/' + schoolClass.id, schoolClass);
            return commit('UPDATE_CLASS', {classIndex, schoolClass: response.data})
        },
        async deleteClass({commit}, {classIndex, classId}) {
            await axios.delete('/api/classes/' + classId);
            commit('DELETE_CLASS', classIndex);
        },
        async deleteStudent({commit}, {studentIndex, studentId}) {
            await axios.delete('/api/users/' + studentId);
            commit('DELETE_STUDENT', studentIndex);
        },
        async inviteStudent({commit, state}, student) {
            const response = await axios.post('/api/classes/' + state.schoolClass.id + '/students', student);
            commit('ADD_STUDENT', response.data);
        }
    }
}