<template>
  <v-data-table
    :headers="headers"
    :items="users"
    :server-items-length="totalUsers"
    :items-per-page="15"
    :options.sync="options"
    disable-filtering
    disable-sort
    :footer-props="{
      'items-per-page-options': [15],
      'show-first-last-page': true,
    }"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Admins</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Create Admin
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="emailRules"
                        :error-messages="error"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <p>Empty</p>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      error: "",
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      headers: [
        { text: "Id", value: "id" },
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        { text: "Role", value: "role.name" },
        { text: "Actions", value: "actions" },
      ],
      dialog: false,
      dialogDelete: false,
      modal: false,
      date: "",
      options: {},
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        role: "Admin",
      },
      defaultItem: {
        id: "",
        name: "",
        role: "Admin",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchUsers(this.options.page);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("user", ["users", "totalUsers"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Admin" : "Edit Admin";
    },
  },
  methods: {
    ...mapActions("user", [
      "fetchUsers",
      "storeUser",
      "updateUser",
      "deleteUser",
    ]),
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteUser({
        userIndex: this.editedIndex,
        userId: this.editedItem.id,
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.editedItem = { id: "", name: "", role: "Admin", email: "" };
      this.error = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.$refs.form.validate();
      await axios
        .get("/api/users/exists?email=" + this.editedItem.email)
        .then(
          (res) =>
            (this.error = res.data ? "User with such email already exist" : "")
        );
      if (this.error || !this.editedItem.email) {
        return;
      } else if (this.editedIndex > -1) {
        this.updateUser({ userIndex: this.editedIndex, user: this.editedItem });
      } else {
        this.storeUser(this.editedItem);
      }
      this.close();
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
</style>