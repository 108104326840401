var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"font-weight-bold"},[_vm._v("School admins")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('span',[_vm._v("Number school admins: ")]),_vm._v(" "+_vm._s(_vm.schoolAdmins.length))]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.fetchAllTeachersBySchoolId(_vm.schoolId)}}},'v-btn',attrs,false),on),[_vm._v(" Add school admin ")])]}}]),model:{value:(_vm.addSchoolAdminModal),callback:function ($$v) {_vm.addSchoolAdminModal=$$v},expression:"addSchoolAdminModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add school admin")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"addSchoolAdminForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[(false)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"name","return-object":"","label":"Select teacher"},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Email","rules":[
                              function (v) { return !!v || 'Scool admin email is required'; },
                              function (v) { return /.+@.+\..+/.test(v) ||
                                'Scool admin email must be valid'; } ],"error-messages":_vm.error,"required":""},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[
                              function (v) { return !!v || 'Scool admin  name is required'; } ],"error-messages":_vm.error,"required":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.$refs.addSchoolAdminForm.validate()
                        ? _vm.addSchoolAdmin()
                        : null}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Email")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.schoolAdmins),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.email))]),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"red"}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-account-remove ")])],1)],1)])}),0)]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }