<template>
  <v-row class="mt-10">
    <v-col>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">Class</th>
            <!-- <th class="text-center" v-for="skill in skills" :key="skill.id">
              {{ skill.name }}
            </th> -->
             <th class="text-center" v-for="skill in skillTitles" :key="skill.index">
              {{ skill }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="schoolClass in schoolClasses" :key="schoolClass.id">
            <td>{{ schoolClass.name }}</td>
            <td v-for="skill in schoolClass.enabledSkills" :key="schoolClass.id + '' + skill.id">
              <div class="d-flex justify-center">
                <v-checkbox @click="toggleSkill(schoolClass.id, skill.id)" v-model="skill.enabled"></v-checkbox>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  props: ['schoolId'],
  data() {
    return {
      skillTitles:['Understand Your Well-Being','Nurture Neuroplasticity', 'Savour Your Sleep',  'Prioritise Positivity','Choose Kindness','Be Your Own Best Friend','Tame Technology','Become Wired for Well-Being',''],
      schoolClasses: []
    }
  },
  computed: {
    ...mapGetters('skill', ['skills'])
  },
  methods: {
    ...mapActions('skill', ['toggleSkill']),
    async fetchClassesWithSkills() {
      const response = await axios.get('/api/schools/' + this.schoolId + '/classes-with-skills');
      this.schoolClasses = response.data;
    },
    async toggleSkill(schoolClassId, skillId) {
      await axios.put('/api/classes/' + schoolClassId + '/skills/' + skillId + '/toggle');
      const schoolClass = this.schoolClasses.find(schoolClass => schoolClass.id === schoolClassId);
      const skill = schoolClass.enabledSkills.find(skill => skill.id === skillId);
      skill.enabled = !!skill.enabled;
    }
  },
  mounted() {
    this.fetchClassesWithSkills(this.schoolId);
  }
}
</script>

<style scoped>

</style>