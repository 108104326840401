<template>
  <Admin>
    <v-tabs>
      <v-tab
        v-for="(tab, index) in tabsTitle"
        :key="'tab-' + index"
        :href="'#tab-' + index"
        style="height: 48px"
      >
        {{ tab }}
      </v-tab>
      <v-tab-item id="tab-0">
        <Teachers></Teachers>
      </v-tab-item>
    </v-tabs>
  </Admin>
</template>

<script>
import Admin from "../../layouts/Admin";
import Teachers from "./Teachers";
export default {
  name: "Dashboard",
  components: { Admin, Teachers },
  data() {
    return {
      tabsTitle: ["Teacher"],
    };
  },
};
</script>

<style>
.v-tabs {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.v-window {
  flex: 1 1 auto !important;
}

.v-window__container {
  height: 100% !important;
}

.v-window-item {
  height: 100%;
}
</style>