import axios from "axios";

export default {
    namespaced: true,
    state: {
        schools: [],
        schoolNames: [],
        school: {},
        totalSchools: 0,
        errors: {}
    },
    getters: {
        schools(state) {
            return state.schools;
        },
        schoolNames(state) {
            return state.schoolNames;
        },
        totalSchools(state) {
            return state.totalSchools;
        },
        school(state) {
            return {
                ...state.school,
                classes: [{ id: 1, name: 'Class 1' }, { id: 2, name: 'Class 2' }, { id: 3, name: 'Class 3' }]
            }
        },
        errors(state) {
            return state.errors;
        }
    },
    mutations: {
        SET_SCHOOLS(state, schools) {
            state.schools = schools
        },
        SET_SCHOOL_NAMES(state, schoolNames) {
            state.schoolNames = schoolNames;
        },
        SET_TOTAL_SCHOOLS(state, totalSchools) {
            state.totalSchools = totalSchools
        },
        SET_SCHOOL(state, school) {
            state.school = school
        },
        ADD_SCHOOL(state, school) {
            state.schools.unshift(school);
            state.totalSchools++;
        },
        RENEW_LICENSE(state, { schoolIndex, license_expire }) {
            state.schools[schoolIndex].license_expire = license_expire;
        },
        DELETE_SCHOOL(state, schoolIndex) {
            state.schools.splice(schoolIndex, 1);
            state.totalSchools--;
        },
        SET_ERRORS(state, errors) {
            state.errors = errors;
        },
        CLEAR_ERRORS(state) {
            state.errors = {};
        }
    },
    actions: {
        async fetchSchools({ commit }, page = 1) {
            const schools = await axios.get('/api/schools?page=' + page);
            commit('SET_SCHOOLS', schools.data.data);
            commit('SET_TOTAL_SCHOOLS', schools.data.total)
        },
        async fetchAllSchools({ commit }) {
            const schools = await axios.get('/api/schools/all');
            commit('SET_SCHOOLS', schools.data);
        },
        async fetchSchoolNames({ commit }) {
            const response = await axios.get('/api/schools/name');
            commit('SET_SCHOOL_NAMES', response.data.schoolNames);
        },

        async fetchSchool({ commit }, schoolId) {
            const response = await axios.get('/api/schools/' + schoolId);
            // console.log("classes:"); console.log(response.data);
            commit('SET_SCHOOL', response.data);
        },

        async storeSchool({ commit }, school) {
            try {
                const response = await axios.post('api/schools', school);
                commit('ADD_SCHOOL', response.data.school);
            } catch (e) {
                commit('SET_ERRORS', e.response.data.errors);
            }
        },
        async renewLicense({ commit }, { schoolIndex, schoolId }) {
            const response = await axios.put('/api/schools/' + schoolId + '/license');
            commit('RENEW_LICENSE', { schoolIndex, license_expire: response.data.license_expire });
        },
        async updateSchool({ commit }, school) {
            try {
                const response = await axios.put('/api/schools/' + school.id, school);
                commit('SET_SCHOOL', response.data.school);
            } catch (e) {
                commit('SET_ERRORS', e.response.data.errors);
            }
        },
        async deleteSchool({ commit }, { schoolIndex, schoolId }) {
            await axios.delete('/api/schools/' + schoolId);
            commit('DELETE_SCHOOL', schoolIndex);
        }
    }
}