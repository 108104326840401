import Vue from "vue"
import Vuex from 'vuex'
import auth from './modules/auth'
import school from './modules/school'
import schoolClass from './modules/class'
import user from './modules/user'
import news from './modules/news'
import notification from "./modules/notification";
import skill from "./modules/skill";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        school,
        schoolClass,
        user,
        news,
        notification,
        skill
    }
})