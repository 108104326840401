<template>
  <Admin>
    <v-data-table
        :headers="headers"
        :items="news"
        :server-items-length="totalNews"
        :items-per-page="15"
        :options.sync="options"
        disable-filtering
        disable-sort
        :footer-props="{
          'items-per-page-options': [15],
          'show-first-last-page': true
        }"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>News</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                Create News
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                  >
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="editedItem.title"
                            label="News title"
                            :rules="[v => !!v || 'News title is required', v => v.length <= 100 || 'Max 100 characters']"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-textarea
                            v-model="editedItem.body"
                            label="Body"
                            :rules="[v => !!v || 'News body is required', v => v.length <= 255 || 'Max 255 characters']"
                        ></v-textarea>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-checkbox
                            v-model="editedItem.published_status"
                            :disabled = checkPublishedNewsAmount
                            :label="`${[!checkPublishedNewsAmount ? 'Published' : 'Only 3 news can be Published']}`"
                        ></v-checkbox>
                        
                        <!-- <v-checkbox
                            v-model="editedItem.published_status"
                            :disabled = checkPublishedNewsAmount
                            label="Published"
                            :rules="[!checkPublishedNewsAmount || 'Only 3 news can be published.']"
                        ></v-checkbox> -->
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-file-input
                            v-if="editedIndex === -1"
                            v-model="editedItem.image"
                            :rules="rules"
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pick an news image"
                            prepend-icon="mdi-camera"
                            label="News image"
                            @change="previewImage"
                        ></v-file-input>
                        <v-file-input
                            v-else
                            v-model="editedItem.image"
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pick an news image"
                            prepend-icon="mdi-camera"
                            label="News image"
                            @change="previewImage"
                        ></v-file-input>
                      </v-col>
                      <v-col
                          cols="12"
                          v-if="imageUrl"
                      >
                        <expandable-image :src="imageUrl"/>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.published_status="{ item }">
        {{ item.published_status ? 'published' : 'unpublished' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p>Empty</p>
      </template>
    </v-data-table>
  </Admin>
</template>

<script>
import axios from "axios";
import Admin from "../layouts/Admin";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    Admin
  },
  data() {
    return {
      valid: true,
      rules: [
        v => !!v || 'News image is required',
      ],
      imageUrl: '',
      dialog: false,
      previewImageDialog: false,
      dialogDelete: false,
      modal: false,
      date: '',
      options: {},
      editedIndex: -1,
      editedItem: {
        id: '',
        title: '',
        body: '',
        image: null,
        published_status: false
      },
      defaultItem: {
        id: '',
        title: '',
        body: '',
        image: [],
        published_status: false
      },
      headers: [
        {text: 'Id', value: 'id'},
        {
          text: 'Title',
          align: 'start',
          value: 'title',
        },
        {text: 'Status', value: 'published_status'},
        {text: 'Actions', value: 'actions'},
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchNews(this.options.page)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('news', ['news', 'totalNews']),

    formTitle() {
      return this.editedIndex === -1 ? 'New News' : 'Edit News'
    },

    checkPublishedNewsAmount() {
      if (this.editedItem.id) { 
        return this.editedItem.published_status==1 ? false : this.news.filter(x => x.published_status==1 && x.id!==this.editedItem.id).length>2 ? true : false
      } else {
      return (this.news.filter(x => x.published_status==1).length>2)
      }
    }
  },
  methods: {
    ...mapActions('news', ['fetchNews', 'storeNews', 'updateNews', 'deleteNews']),
    editItem(item) {
      this.editedIndex = this.news.indexOf(item)
      axios.get('/api/news/' + item.id + '/image', {responseType: 'blob'})
      .then(response => {
        // item.image = response.data;
        this.imageUrl = URL.createObjectURL(response.data);
      })
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    previewImage() {
      if (this.editedItem.image) {
        this.imageUrl = URL.createObjectURL(this.editedItem.image);
      } else {
        this.imageUrl = '';
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex === -1) {
          this.storeNews(this.editedItem);
        } else {
          if (!this.editedItem.image) {
            this.editedItem.image = ''
          }
          this.updateNews({newsIndex: this.editedIndex, news: this.editedItem});
        }
        this.close()
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
        this.imageUrl = ''
        this.editedItem.image = ''
      })
    },
    deleteItem(item) {
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteNews({newsIndex: this.editedIndex, newsId: this.editedItem.id});
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem)
      this.$refs.form.resetValidation()
    },


  },
  mounted() {
    this.fetchNews();
  },
}
</script>