<template>
  <v-card elevation="2">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold"
          >School admins</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6" class="text-left">
              <span>Number school admins: </span>
              {{ schoolAdmins.length }}</v-col
            >
            <v-col cols="6" class="text-right">
              <v-dialog
                v-model="addSchoolAdminModal"
                persistent
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchAllTeachersBySchoolId(schoolId)"
                  >
                    Add school admin
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Add school admin</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form
                        ref="addSchoolAdminForm"
                        v-model="valid"
                        lazy-validation
                      >
                        <v-row>
                          <v-col cols="12" v-if="false">
                          <!-- <v-col cols="12" v-if="users && users.length > 0"> -->
                            <v-autocomplete
                              v-model="editedItem"
                              :items="users"
                              item-text="name"
                              return-object
                              label="Select teacher"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email"
                              :rules="[
                                (v) => !!v || 'Scool admin email is required',
                                (v) =>
                                  /.+@.+\..+/.test(v) ||
                                  'Scool admin email must be valid',
                              ]"
                              :error-messages="error"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="Name"
                              v-model="editedItem.name"
                              :rules="[
                                (v) => !!v || 'Scool admin  name is required',
                              ]"
                              :error-messages="error"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="
                        $refs.addSchoolAdminForm.validate()
                          ? addSchoolAdmin()
                          : null
                      "
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in schoolAdmins" :key="index">
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-left">{{ item.email }}</td>
                  <td>
                    <v-btn icon color="red">
                      <v-icon @click="deleteItem(item)">
                        mdi-account-remove
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "SchoolAdminsCard",

  data() {
    return {
      error: "",
      flag: true,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      addSchoolAdminModal: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        email: "",
        school_id: "",
      },
    };
  },
  props: {
    schoolId: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("user", ["users", "schoolAdmins"]),
  },
  methods: {
    ...mapActions("user", [
      "storeSchoolAdmin",
      "deleteSchoolAdmin",
      "fetchSchoolAdminsById",
      "fetchAllTeachersBySchoolId",
    ]),

    async addSchoolAdmin() {
      this.$refs.addSchoolAdminForm.validate();

      const user = {
        name: this.editedItem.name,
        email: this.editedItem.email,
        role: "School Admin",
        school_id: this.schoolId,
      };

      const res = await axios.get(
        "/api/users/school-admin/check?email=" +
          this.editedItem.email +
          "&school_id=" +
          this.schoolId +
          "&name=" +
          this.editedItem.name
      );
  
      this.error = !res.data ? "User with such email already exist" : "";

      if (this.error || !this.editedItem.email || !this.editedItem.name) {
        return;
      } else {
        this.storeSchoolAdmin(user);
      }
      this.close();
    },

    close() {
      this.addSchoolAdminModal = false;
      this.$refs.addSchoolAdminForm.resetValidation();
      this.$refs.addSchoolAdminForm.reset();
      (this.error = ""),
        (this.editedItem = {
          id: "",
          name: "",
          role: "School Admin",
          email: "",
        });
    },

    deleteItem(item) {
      this.editedIndex = this.schoolAdmins.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteSchoolAdmin({
        userIndex: this.editedIndex,
        userId: this.editedItem.id,
      });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  mounted() {
    this.editedItem.school_id = this.schoolId;
    if (this.schoolId) {
      this.fetchSchoolAdminsById(this.schoolId);
    }
  },
};
</script>

