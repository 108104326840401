import axios from "axios";

export default {
    namespaced: true,
    state: {
        news: [],
        publishedNewsCount: 0,
        totalNews: 0
    },
    getters: {
        news(state) {
            return state.news;
        },
        totalNews(state) {
            return state.totalNews;
        }
    },
    mutations: {
        SET_NEWS(state, news) {
            state.news = news
        },
        SET_TOTAL_NEWS(state, totalNews) {
            state.totalNews = totalNews
        },
        ADD_NEWS(state, newNews) {
            state.news.unshift(newNews);
            state.totalNews++;
        },
        UPDATE_NEWS(state, {newsIndex, news}) {
            state.news.splice(newsIndex, 1, news);
        },
        DELETE_NEWS(state, newsIndex) {
            state.news.splice(newsIndex, 1);
            state.totalNews--;
        },
        SET_PUBLISHED_NEWS_COUNT(state) {
            state.publishedNewsCount = state.news.reduce((accumulator, currentValue) => {
                if (currentValue.published_status) {
                    return accumulator + 1;
                }

                return accumulator + 0;
            }, 0);
        }
    },
    actions: {
        async fetchNews({commit}, page = 1) {
            const response = await axios.get('/api/news?page=' + page);
            commit('SET_NEWS', response.data.data);
            commit('SET_TOTAL_NEWS', response.data.total)
            commit('SET_PUBLISHED_NEWS_COUNT');
        },
        // async fetchSchool({commit}, schoolId) {
        //     const response = await axios.get('api/schools/' + schoolId);
        //     commit('SET_SCHOOL', response.data);
        // },
        // eslint-disable-next-line no-unused-vars
        async storeNews({commit}, news) {
            const formData = new FormData();
            formData.append('title', news.title);
            formData.append('body', news.body);
            formData.append('published_status', news.published_status);
            formData.append('image', news.image);
            const response = await axios.post('api/news', formData, { headers: { 'Content-Type': 'multipart/form-data' }});
            commit('ADD_NEWS', response.data.news);
            commit('SET_PUBLISHED_NEWS_COUNT');
        },
        async updateNews({commit}, {newsIndex, news}) {
            const formData = new FormData();
            formData.append('title', news.title);
            formData.append('body', news.body);
            formData.append('published_status', news.published_status);
            formData.append('image', news.image);
            formData.append('_method', 'PUT');
            const response = await axios.post('/api/news/' + news.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            news = response.data.news;
            commit('UPDATE_NEWS', {newsIndex , news});
            commit('SET_PUBLISHED_NEWS_COUNT');
        },
        async deleteNews({commit}, {newsIndex, newsId}) {
            await axios.delete('/api/news/' + newsId);
            commit('DELETE_NEWS', newsIndex);
            commit('SET_PUBLISHED_NEWS_COUNT');
        }
    }
};