<template>
  <Admin>
    <v-container fluid>
      <v-row class="mb-5">
        <v-col>
          <v-card elevation="2" class="text-left">
            <v-card-title>
              <v-row>
                <v-col cols="6">School info</v-col>
                <v-col cols="6" class="text-right">
                  <v-dialog
                    v-model="editSchoolModal"
                    persistent
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="editedSchool = Object.assign({}, school)"
                      >
                        Edit School
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Edit school</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form
                            ref="editSchoolForm"
                            v-model="valid"
                            lazy-validation
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  label="Name"
                                  v-model="editedSchool.name"
                                  :rules="[
                                    (v) => !!v || 'School name is required',
                                  ]"
                                  :error-messages="errors.name"
                                  @focus="CLEAR_ERRORS"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  label="Address"
                                  v-model="editedSchool.address"
                                  :rules="[
                                    (v) => !!v || 'School address is required',
                                  ]"
                                  :error-messages="errors.name"
                                  @focus="CLEAR_ERRORS"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="editSchoolModal = false"
                        >
                          Close
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="editSchool">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <span class="font-weight-bold">Name: </span>{{ school.name }}
                </v-col>
                <v-col>
                  <span class="font-weight-bold">Address: </span
                  >{{ school.address }}
                </v-col>
                <v-col>
                  <span class="font-weight-bold">License end date: </span
                  >{{ school.license_expire }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="font-weight-bold">Number of classes: </span>
                  {{ schoolClasses.length }}
                </v-col>
                <v-col>
                  <span class="font-weight-bold">Users: </span>
                  {{
                    schoolClasses.reduce((a, b) => a + b["students_count"], 0)
                  }}
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <school-admins-card
            v-if="user.role.name == 'Super Admin' || user.role.name == 'Admin'"
            :schoolId="schoolId || user.school_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="2">
            <v-card-title>
              <v-row>
                <v-col cols="10" class="text-left">Classes</v-col>
                <v-col cols="2" class="text-right">
                  <!--                  Create class modal                                      -->
                  <v-dialog
                    v-model="createClassModal"
                    persistent
                    max-width="1000px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="fetchFreeTeachersBySchoolId(schoolId)"
                      >
                        Create Class
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Create class</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form
                            ref="createClassForm"
                            v-model="valid"
                            lazy-validation
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  label="Name"
                                  v-model="schoolClass.name"
                                  :rules="[
                                    (v) => !!v || 'Class name is required',
                                  ]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-autocomplete
                                  v-if="users.length"
                                  v-model="schoolClass.teacher"
                                  :items="users"
                                  item-text="name"
                                  return-object
                                  label="Select teacher"
                                ></v-autocomplete>
                              </v-col>

                              <v-col cols="6">
                                <v-text-field
                                  label="Teacher name"
                                  v-model="schoolClass.teacher.name"
                                  :rules="[
                                    (v) => !!v || 'Teacher name is required',
                                  ]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  label="Teacher email"
                                  v-model="schoolClass.teacher.email"
                                  :rules="[
                                    (v) => !!v || 'Teacher email is required',
                                    (v) =>
                                      /.+@.+\..+/.test(v) ||
                                      'Teacher email must be valid',
                                  ]"
                                  :error-messages="schoolClass.teacher.error"
                                  @blur="
                                    checkTeacherExistence(
                                      schoolClass.teacher,
                                      schoolClass.students
                                    )
                                  "
                                  @focus="schoolClass.teacher.error = ''"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-form ref="addStudentForm">
                                  <v-row>
                                    <v-col cols="3">
                                      <v-text-field
                                        label="Student name"
                                        v-model="student.name"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Student name is required',
                                        ]"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-text-field
                                        label="Student email"
                                        v-model="student.email"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Student email is required',
                                          (v) =>
                                            /.+@.+\..+/.test(v) ||
                                            'Student email must be valid',
                                        ]"
                                        :error-messages="student.error"
                                        @focus="student.error = ''"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                      <v-text-field
                                        label="Parent name"
                                        v-model="student.parent_name"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Parent name is required',
                                        ]"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                      <v-text-field
                                        label="Parent email"
                                        v-model="student.parent_email"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Parent email is required',
                                          (v) =>
                                            /.+@.+\..+/.test(v) ||
                                            'Student email must be valid',
                                        ]"
                                        :error-messages="student.error"
                                        @focus="student.error = ''"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-row class="align-center fill-height">
                                        <v-col>
                                          <v-row>
                                            <v-btn
                                              icon
                                              color="green"
                                              @click="
                                                $refs.addStudentForm.validate()
                                                  ? checkUserExistence(
                                                      student,
                                                      null,
                                                      addStudent,
                                                      schoolClass.students
                                                    )
                                                  : null
                                              "
                                            >
                                              <v-icon>mdi-account-plus</v-icon>
                                            </v-btn>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                              <v-col
                                cols="12"
                                v-for="(student, index) in schoolClass.students"
                                :key="'create-class-' + index"
                              >
                                <v-row>
                                  <v-col cols="6">
                                    <v-text-field
                                      label="Student name"
                                      v-model="student.name"
                                      :rules="[
                                        (v) =>
                                          !!v || 'Student name is required',
                                      ]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="5">
                                    <v-text-field
                                      label="Student email"
                                      v-model="student.email"
                                      :rules="[
                                        (v) =>
                                          !!v || 'Student email is required',
                                        (v) =>
                                          /.+@.+\..+/.test(v) ||
                                          'Student email must be valid',
                                      ]"
                                      @blur="
                                        checkUserExistence(
                                          student,
                                          index,
                                          null,
                                          schoolClass.students
                                        )
                                      "
                                      :error-messages="student.error"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="1">
                                    <v-row class="align-center fill-height">
                                      <v-col>
                                        <v-row>
                                          <v-btn icon color="red">
                                            <v-icon
                                              @click="removeStudent(index)"
                                              >mdi-account-remove</v-icon
                                            >
                                          </v-btn>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeCreateClassModal"
                        >
                          Close
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="saveClass">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(schoolClass, index) in schoolClasses"
                  :key="schoolClass.id"
                >
                  <v-expansion-panel-header>{{
                    schoolClass.name
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="8" class="text-left">
                        <p>
                          <span class="font-weight-bold">Teacher name: </span>
                          {{ schoolClass.teacher.name }}
                          <span class="font-weight-bold ml-5"
                            >Teacher email:
                          </span>
                          {{ schoolClass.teacher.email }}
                          <v-dialog v-model="dialogDelete" max-width="550px">
                            <v-card>
                              <v-card-title class="headline"
                                >Are you sure you want to delete this
                                class?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="dialogDelete = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="
                                    deleteItemConfirm(index, schoolClass.id)
                                  "
                                  >OK
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </p>
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <v-btn @click="editClass(index)" color="success">
                          Update class
                        </v-btn>
                        <!--                        Edit class modal-->
                        <v-dialog
                          v-model="editClassModal"
                          persistent
                          max-width="1000px"
                        >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Update class</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-form
                                  ref="updateClassForm"
                                  v-model="valid"
                                  lazy-validation
                                >
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field
                                        label="Name"
                                        v-model="editedSchoolClass.name"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Class name is required',
                                        ]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        label="Teacher name"
                                        v-model="editedSchoolClass.teacher.name"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Teacher name is required',
                                        ]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="5">
                                      <v-text-field
                                        v-if="!deletedTeacher"
                                        label="Teacher email"
                                        v-model="
                                          editedSchoolClass.teacher.email
                                        "
                                        disabled
                                      ></v-text-field>
                                      <v-text-field
                                        v-else
                                        label="Teacher email"
                                        v-model="
                                          editedSchoolClass.teacher.email
                                        "
                                        :rules="[
                                          (v) =>
                                            !!v || 'Teacher email is required',
                                          (v) =>
                                            /.+@.+\..+/.test(v) ||
                                            'Teacher email must be valid',
                                        ]"
                                        :error-messages="
                                          editedSchoolClass.teacher.error
                                        "
                                        @focus="
                                          editedSchoolClass.teacher.error = ''
                                        "
                                        @blur="
                                          checkTeacherExistence(
                                            editedSchoolClass.teacher,
                                            [
                                              ...editedSchoolClass.students,
                                              ...editedSchoolClass.addedStudents,
                                            ]
                                          )
                                        "
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="1" class="text-left">
                                      <v-row class="align-center fill-height">
                                        <v-btn icon color="red">
                                          <v-icon @click="removeTeacher">
                                            mdi-account-remove
                                          </v-icon>
                                        </v-btn>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-form ref="addStudentForm">
                                        <v-row>
                                          <v-col cols="3">
                                            <v-text-field
                                              label="Student name"
                                              v-model="student.name"
                                              :rules="[
                                                (v) =>
                                                  !!v ||
                                                  'Student name is required',
                                              ]"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="3">
                                            <v-text-field
                                              label="Student email"
                                              v-model="student.email"
                                              :rules="[
                                                (v) =>
                                                  !!v ||
                                                  'Student email is required',
                                                (v) =>
                                                  /.+@.+\..+/.test(v) ||
                                                  'Student email must be valid',
                                              ]"
                                              :error-messages="student.error"
                                              @focus="student.error = ''"
                                            ></v-text-field>
                                          </v-col>

                                          <v-col cols="3">
                                            <v-text-field
                                              label="Parent name"
                                              v-model="student.parent_name"
                                              :rules="[
                                                (v) =>
                                                  !!v ||
                                                  'Parent name is required',
                                              ]"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="2">
                                            <v-text-field
                                              label="Parent email"
                                              v-model="student.parent_email"
                                              :rules="[
                                                (v) =>
                                                  !!v ||
                                                  'Parent email is required',
                                                (v) =>
                                                  /.+@.+\..+/.test(v) ||
                                                  'Parent email must be valid',
                                              ]"
                                              :error-messages="student.error"
                                              @focus="student.error = ''"
                                            ></v-text-field>
                                          </v-col>

                                          <v-col cols="1">
                                            <v-row
                                              class="align-center fill-height"
                                            >
                                              <v-col>
                                                <v-row>
                                                  <v-btn
                                                    icon
                                                    color="green"
                                                    @click="
                                                      $refs.addStudentForm[0].validate()
                                                        ? checkUserExistence(
                                                            student,
                                                            null,
                                                            addStudentToAdded,
                                                            [
                                                              ...editedSchoolClass.students,
                                                              ...editedSchoolClass.addedStudents,
                                                            ]
                                                          )
                                                        : null
                                                    "
                                                  >
                                                    <v-icon
                                                      >mdi-account-plus</v-icon
                                                    >
                                                  </v-btn>
                                                </v-row>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-form>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      v-for="(student,
                                      index) in editedSchoolClass.students"
                                      :key="'edit-class-' + index"
                                    >
                                      <v-row>
                                        <v-col cols="3">
                                          <v-text-field
                                            label="Student name"
                                            v-model="student.name"
                                            :rules="[
                                              (v) =>
                                                !!v ||
                                                'Student name is required',
                                            ]"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-text-field
                                            label="Student email"
                                            v-model="student.email"
                                            disabled
                                          ></v-text-field>
                                        </v-col>

                                        <v-col
                                          v-if="
                                            !!student.parent &&
                                              !!student.parent.email &&
                                              !!student.parent.name
                                          "
                                          cols="3"
                                        >
                                          <v-text-field
                                            label="Parent name"
                                            v-model="student.parent.name"
                                            disabled
                                          ></v-text-field>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            !!student.parent &&
                                              !!student.parent.email &&
                                              !!student.parent.name
                                          "
                                          cols="2"
                                        >
                                          <v-text-field
                                            label="Parent email"
                                            v-model="student.parent.email"
                                            disabled
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="1">
                                          <v-row
                                            class="align-center fill-height"
                                          >
                                            <v-col>
                                              <v-row>
                                                <v-btn icon color="red">
                                                  <v-icon
                                                    @click="
                                                      addStudentToRemoved(
                                                        index,
                                                        student.id
                                                      )
                                                    "
                                                  >
                                                    mdi-account-remove
                                                  </v-icon>
                                                </v-btn>
                                              </v-row>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>

                                    <v-col
                                      cols="12"
                                      v-for="(student,
                                      index) in editedSchoolClass.addedStudents"
                                      :key="index"
                                    >
                                      <v-row>
                                        <v-col cols="3">
                                          <v-text-field
                                            label="Student name"
                                            v-model="student.name"
                                            :rules="[
                                              (v) =>
                                                !!v ||
                                                'Student name is required',
                                            ]"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-text-field
                                            label="Student email"
                                            v-model="student.email"
                                            @blur="
                                              checkUserExistence(
                                                student,
                                                index,
                                                null,
                                                [
                                                  ...editedSchoolClass.addedStudents,
                                                  ...editedSchoolClass.students,
                                                ]
                                              )
                                            "
                                            :error-messages="student.error"
                                            :rules="[
                                              (v) =>
                                                !!v ||
                                                'Student email is required',
                                              (v) =>
                                                /.+@.+\..+/.test(v) ||
                                                'Student email must be valid',
                                            ]"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col
                                          v-if="
                                            !!student.parent_name &&
                                              !!student.parent_email
                                          "
                                          cols="3"
                                        >
                                          <v-text-field
                                            label="Parent name"
                                            v-model="student.parent_name"
                                            disabled
                                          ></v-text-field>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            !!student.parent_name &&
                                              !!student.parent_email
                                          "
                                          cols="2"
                                        >
                                          <v-text-field
                                            label="Parent email"
                                            v-model="student.parent_email"
                                            disabled
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="1">
                                          <v-row
                                            class="align-center fill-height"
                                          >
                                            <v-col>
                                              <v-row>
                                                <v-btn icon color="red">
                                                  <v-icon
                                                    @click="
                                                      removeStudentFromAdded(
                                                        index
                                                      )
                                                    "
                                                    >mdi-account-remove
                                                  </v-icon>
                                                </v-btn>
                                              </v-row>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEditClassModal"
                              >
                                Close
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="updateClass(index)"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-btn
                          @click="dialogDelete = true"
                          color="error"
                          class="ml-5"
                        >
                          Delete class
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row>
                      <v-col cols="12" class="text-left">
                        <h3>Students</h3>
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-left"
                        v-for="student in schoolClass.students"
                        :key="'student-' + student.id"
                      >
                        <p>
                          <span class="font-weight-bold">Student name: </span>
                          {{ student.name }}
                          <span class="font-weight-bold ml-5"
                            >Student email:
                          </span>
                          {{ student.email }}

                          <span
                            v-if="
                              !!student &&
                                !!student.parent &&
                                !!student.parent.name &&
                                !!student.parent.email
                            "
                          >
                            <v-icon
                              :color="
                                `${student.parent_consent ? 'success' : ''}`
                              "
                              size="large"
                              class="ml-5 mr-2"
                              >mdi-check-bold</v-icon
                            >
                            {{
                              `Parent Consent: ${
                                student.parent_consent ? "Approved" : "Waiting"
                              }`
                            }}
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <!--            <v-row justify="center">-->
            <!--              -->
            <!--            </v-row>-->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </Admin>
</template>

<script>
import Admin from "../../../layouts/Admin";
import SchoolAdminsCard from "./c/SchoolAdminsCard";

import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "SchoolDetail",
  components: { Admin, SchoolAdminsCard },
  data() {
    return {
      // schoolId: this.$route.params.id || null,
      error: "",
      flag: true,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      createClassModal: false,
      editSchoolModal: false,
      dialogDelete: false,
      editClassModal: false,
      deletedTeacher: false,
      userExistsError: "",
      teacherExistsError: "",
      editedSchool: {
        name: "",
        email: "",
      },
      schoolClass: {
        name: "",
        teacher: {
          name: "",
          email: "",
          error: "",
        },
        students: [],
      },
      editedIndex: -1,
      editedSchoolClass: {
        name: "",
        teacher: {
          name: "",
          email: "",
          error: "",
        },
        students: [],
        deletedStudents: [],
        addedStudents: [],
      },
      defaultSchoolClass: {
        name: "",
        teacher: {
          name: "",
          email: "",
          parent_name: "",
          parent_email: "",
          error: "",
        },
        students: [],
        deletedStudents: [],
        addedStudents: [],
      },
      student: {
        name: "",
        email: "",
        error: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("user", ["users", "totalUsers"]),
    ...mapGetters("school", ["school", "errors"]),
    ...mapGetters("schoolClass", ["schoolClasses"]),
    schoolId() {
      return (this.$route.params.id || this.user.school_id).toString();
    },
  },
  methods: {
    ...mapActions("user", ["fetchFreeTeachersBySchoolId", "storeUser"]),
    ...mapActions("school", ["fetchSchool", "fetchOneSchool", "updateSchool"]),
    ...mapActions("schoolClass", [
      "fetchClasses",
      "fetchOneSchoolClasses",
      "storeClass",
      "deleteClass",
    ]),
    ...mapActions("schoolClass", { updateClassAction: "updateClass" }),
    ...mapMutations("school", ["CLEAR_ERRORS"]),

    addStudent() {
      this.schoolClass.students.push({ ...this.student });
      this.student.name = this.student.email = this.student.parent_name = this.student.parent_email =
        "";
      this.$refs.addStudentForm.resetValidation();
    },
    removeStudent(index) {
      this.schoolClass.students.splice(index, 1);
    },
    saveClass() {
      this.schoolClass.students.forEach((student, index) => {
        if (this.flag) {
          this.checkUserExistence(
            student,
            index,
            null,
            this.schoolClass.students
          );
        }
      });

      if (
        this.schoolClass.teacher.error ||
        this.schoolClass.students.some((student) => student.error)
      ) {
        this.flag = false;
        return;
      }

      if (this.$refs.createClassForm.validate()) {
        this.storeClass({
          schoolId: this.school.id,
          schoolClass: this.schoolClass,
        });
        this.createClassModal = false;
        this.schoolClass = Object.assign({}, this.defaultSchoolClass);
        this.$refs.createClassForm.resetValidation();
      }
      this.student = {
        name: "",
        email: "",
        parent_name: "",
        parent_email: "",
        error: "",
      };
      this.editedSchoolClass.addedStudents.length = 0;
      this.schoolClass.students.length = 0;
      this.schoolClass.teacher = { name: "", email: "" };
    },
    editSchool() {
      if (this.$refs.editSchoolForm.validate()) {
        this.updateSchool(this.editedSchool);
        this.editSchoolModal = false;
      }
    },
    deleteItemConfirm(classIndex, classId) {
      this.deleteClass({ classIndex, classId });
      this.dialogDelete = false;
    },
    editClass(index) {
      this.editedIndex = index;
      this.editedSchoolClass = Object.assign(
        this.editedSchoolClass,
        JSON.parse(JSON.stringify(this.schoolClasses[index]))
      );
      this.editedSchoolClass.teacher.error = "";
      this.editClassModal = true;
    },
    addStudentToRemoved(index, id) {
      this.editedSchoolClass.deletedStudents.push(id);
      this.editedSchoolClass.students.splice(index, 1);
    },
    addStudentToAdded() {
      this.editedSchoolClass.addedStudents.push({ ...this.student });
      this.student.name = this.student.email = this.student.parent_name = this.student.parent_email =
        "";
      this.$refs.addStudentForm[0].resetValidation();
    },
    removeStudentFromAdded(index) {
      this.editedSchoolClass.addedStudents.splice(index, 1);
    },
    removeTeacher() {
      this.deletedTeacher = true;
      this.editedSchoolClass.teacher = {
        ...this.editedSchoolClass.teacher,
        email: "",
        name: "",
        error: "",
      };
      this.$refs.updateClassForm[0].resetValidation();
    },
    updateClass() {
      this.editedSchoolClass.addedStudents.forEach((student, index) => {
        if (this.flag) {
          this.checkUserExistence(student, index, null, [
            ...this.editedSchoolClass.addedStudents,
            ...this.editedSchoolClass.students,
          ]);
        }
      });

      if (
        this.editedSchoolClass.teacher.error ||
        this.editedSchoolClass.addedStudents.some((student) => student.error)
      ) {
        this.flag = false;
        return;
      }
      if (this.$refs.updateClassForm[0].validate()) {
        this.updateClassAction({
          classIndex: this.editedIndex,
          schoolClass: this.editedSchoolClass,
        });
        this.editClassModal = false;
      }
      this.student = {
        name: "",
        email: "",
        parent_name: "",
        parent_email: "",
        error: "",
      };
      this.editedSchoolClass.addedStudents.length = 0;
    },
    closeCreateClassModal() {
      this.createClassModal = false;
      this.$refs.createClassForm.resetValidation();
      this.schoolClass = Object.assign(
        {},
        JSON.parse(JSON.stringify(this.defaultSchoolClass))
      );
    },
    checkTeacherExistence(teacher, students) {
      if (students.some((student) => student.email === teacher.email)) {
        return (teacher.error = "User with such email already exist");
      }

      axios
        .get("/api/users/exists?email=" + teacher.email)
        .then(
          (res) =>
            (teacher.error = res.data
              ? "User with such email already exist"
              : "")
        );
    },

    checkUserExistence(user, index = null, callback = null, users) {
      user.error = "";
      if (this.schoolClass.teacher.email === user.email) {
        this.flag = false;
        return (user.error = "User with such email already exist");
      }
      if (index === null) {
        if (users.some((student) => student.email === user.email)) {
          this.flag = false;
          return (user.error = "User with such email already exist");
        }
      } else {
        if (
          users.some((student, idx) => {
            if (index !== idx) {
              return student.email === user.email;
            }
          })
        ) {
          this.flag = false;
          return (user.error = "User with such email already exist");
        }
      }
      axios.get("/api/users/exists/student?email=" + user.email).then((res) => {
        if (!res.data) {
          if (callback) {
            callback();
          }
        } else {
          this.flag = false;
          return (user.error = "User with such email already exist");
        }
      });
    },

    closeEditClassModal() {
      this.student = {
        name: "",
        email: "",
        parent_name: "",
        parent_email: "",
        error: "",
      };
      this.editedSchoolClass.addedStudents.length = 0;
      this.editedSchoolClass.deletedStudents = [];
      this.editClassModal = false;
    },
  },
  mounted() {
    this.fetchSchool(this.schoolId);
    this.fetchClasses(this.schoolId);
  },
};
</script>
