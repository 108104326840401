<template>
  <v-container fluid style="height: 100%;">
    <v-row style="height: 100%;">
      <v-col cols="4" style="height: 100%; overflow-y: auto;">
        <v-card
            v-for="(notification, index) in notifications"
            :key="index"
            elevation="2"
            class="mb-5"
        >
          <v-card-title>{{ notification.title }}</v-card-title>
          <v-card-text class="text-left">
            <p>
              {{ notification.body }}
            </p>
            <p>
              {{ formatDate(notification.created_at) }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="notification.title"
                    label="Notification title"
                    :rules="[
                        v => !!v || 'Title is required',
                        v => (v && v.length <= 100) || 'Title must be less than 100 characters'
                    ]"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-select
                    v-model="notification.group"
                    :items="userTypes"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="notification.body"
                    label="Notification text"
                    :rules="[
                        v => !!v || 'Text is required',
                        v => (v && v.length <= 255) || 'Text must be less than 255 characters'
                    ]"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="submit">Send</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      valid: true,
      notification: {
        title: '',
        body: '',
        group: 'All users'
      },
    }
  },
  computed: {
    ...mapGetters('notification', ['notifications']),
    ...mapGetters('school', ['schoolNames']),
    userTypes() {
      return [
        'All users',
        'Free users',
        ...this.schoolNames
      ];
    }
  },
  methods: {
    ...mapActions('notification', ['fetchNotifications', 'storeNotification']),
    ...mapActions('school', ['fetchSchoolNames']),

    async submit() {
     this.$refs.form.validate();
      if (this.notification.title && this.notification.title) {
      await this.storeNotification(this.notification);
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      }
    },
    
    formatDate(timestamp) {
      const dateObj = new Date(timestamp);
      let date = dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
      let month = ("0" + (dateObj .getMonth() + 1)).slice(-2);

      return date + '.' + month + '.' + dateObj.getFullYear();
    }
  },
  mounted() {
    this.fetchNotifications();
    this.fetchSchoolNames();
  }
}
</script>

