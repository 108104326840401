<template>
  <Admin>
    <v-data-table
        :headers="headers"
        :items="schools"
        :server-items-length="totalSchools"
        :items-per-page="15"
        :options.sync="options"
        disable-filtering
        disable-sort
        :footer-props="{
          'items-per-page-options': [15],
          'show-first-last-page': true
        }"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Schools</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                Create School
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                  >
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="editedItem.name"
                            label="School name"
                            :rules="[v => !!v || 'School name is required']"
                            :error-messages="errors.name"
                            @focus="CLEAR_ERRORS"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="editedItem.address"
                            label="Address"
                            :rules="[v => !!v || 'School address is required']"
                            :error-messages="errors.address"
                            @focus="CLEAR_ERRORS"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="editedItem.license_start"
                            persistent
                            width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedItem.license_start"
                                label="License start"
                                prepend-icon="mdi-calendar"
                                readonly
                                :rules="[v => !!v || 'Licence start is required']"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="editedItem.license_start"
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(editedItem.license_start)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="$router.push({name: 'schoolDetail', params: {id: item.id}})"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p>Empty</p>
      </template>
      <template v-slot:item.renew_license="{ item }">
        <v-btn
            :disabled="!canRenew(item.license_expire)"
            @click="renewLicense(item)"
        >
          Renew license
        </v-btn>
      </template>
    </v-data-table>
  </Admin>
</template>

<script>
import Admin from "../../layouts/Admin";
import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
  components: {
    Admin
  },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      modal: false,
      date: '',
      options: {},
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        address: '',
        license_start: ''
      },
      defaultItem: {
        id: '',
        name: '',
        address: '',
        license_start: ''
      },
      headers: [
        {text: 'Id', value: 'id'},
        {
          text: 'School name',
          align: 'start',
          value: 'name',
        },
        {text: 'Address', value: 'address'},
        {text: 'Number of Classrooms', align: 'center', value: 'classes_count'},
        {text: 'License start', value: 'license_start'},
        {text: 'License end', value: 'license_expire'},
        {text: 'Renew license', value: 'renew_license'},
        {text: 'Actions', value: 'actions'},
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchSchools(this.options.page)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('school', ['schools', 'totalSchools', 'errors']),
    formTitle() {
      return this.editedIndex === -1 ? 'New School' : 'Edit School'
    },
  },
  mounted() {
    this.fetchSchools();
  },
  methods: {
    ...mapActions('school', ['fetchSchools', 'storeSchool', 'deleteSchool']),
    ...mapActions('school', {
      renewLicenseAction: 'renewLicense'
    }),
    ...mapMutations('school', ['CLEAR_ERRORS']),
    canRenew(licenseEnd) {
      const date = new Date(licenseEnd);
      const now = new Date();
      const diffInTime = now.getTime() - date.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);

      return diffInDays >= -1;
    },
    renewLicense(item) {
      const payload = {schoolIndex: this.schools.indexOf(item), schoolId: item.id};
      this.renewLicenseAction(payload)
    },
    editItem(item) {
      this.editedIndex = this.schools.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.schools.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteSchool({schoolIndex: this.editedIndex, schoolId: this.editedItem.id});
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$refs.form.resetValidation();
      this.CLEAR_ERRORS();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.$refs.form.validate()) {
        this.storeSchool(this.editedItem);
        if (!Object.keys(this.errors).length) {
          this.close();
        }
      }
    }
  }
}
</script>
